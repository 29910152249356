$title__font-family: null !default;

.title{
  font-family: $title__font-family;
}

.title__is-call-out{
  text-align: center;
  border-radius: $base__border__radius;
  padding: rem-calc(10);
  border: 1px solid $grey;
  background: $grey-lighter;
  &.is-warning{
    background: #fefdf5;
    border-color: #f7df6c;
  }
}
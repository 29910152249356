.a_d_z{
  &__section{
    text-align: center;
    margin: rem-calc( $gap * 2 ) 0;
    padding: 0 rem-calc( $gap / 2 );
  }
  &__card__column{
    position: relative;
  }
  &__card{
  }
  &__card-image{

  }
  &__card-footer{
    &--floating{
      position: absolute;
      right: rem-calc( $gap / 2 );
      bottom: rem-calc( $gap / 2 );
      left: rem-calc( $gap / 2 );
    }
  }
}

$app-slides__pagination__bullet__background--active: #007aff !default;
$themeColor: #ffffff;

@import "~swiper/components/core/core";
@import "~swiper/components/lazy/lazy";
@import "~swiper/components/navigation/navigation";
@import "~swiper/components/pagination/pagination";

.swiper-container{
  width: 100%;
  height: 100%;
}

.swiper__slide{
  height: auto;
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &--home-1{
    &:before{
      background-image: url(/assets/img/hero/home-slide-1--2023--small.jpg);
    }
  }
  &--home-2{
    &:before{
      background-image: url(/assets/img/hero/home-slide-2-r3.png);
      opacity: .2;
    }
  }
  &--home-3{
    &:before{
      background-image: url(/assets/img/hero/home-slide-3.jpg);
    }
  }
}

.swiper-button-prev,
.swiper-button-next{
  display: none;
  background-repeat: no-repeat;
  &:after{
    display: none;
  }
}

.swiper-pagination-bullet-active{
  background: $app-slides__pagination__bullet__background--active;
}


@include tablet{
  .swiper__slide{
    &--home-1{
    }
    &--home-2{
    }
  }
  .swiper-button-prev,
  .swiper-button-next{
    display: block;
    position: absolute;
    top: 50%;
    width: 27px;
    height: 44px;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    background-size: 27px 44px;
    background-position: 50%;
    background-repeat: no-repeat;
  }
  .swiper-button-prev{
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23007aff'/%3E%3C/svg%3E");
    left: 10px;
    right: auto;
  }
  .swiper-button-next{
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23007aff'/%3E%3C/svg%3E");
    right: 10px;
    left: auto;
  }
}

@include desktop{
  .swiper__slide{
    background-size: contain;
    &--home-1{
      &:before{
        background-image: url(/assets/img/hero/home-slide-1--2023.png);
        opacity: .5;
      }
    }
    &--home-2{
      &:before{
        background-image: url(/assets/img/hero/home-slide-2-r3.png);
        opacity: .5;
      }
    }
  }
}

@include widescreen{
  .swiper__slide{
    &--home-1{
      &:before{
        background-image: url(/assets/img/hero/home-slide-1--2023.png);
        background-size: contain;
        background-position: left;
        opacity: 1;
      }
    }
    &--home-2{
      &:before{
        background-image: url(/assets/img/hero/home-slide-2-r3.png);
        background-size: contain;
        background-position: right;
        opacity: 1;
      }
    }
  }
}

$icon__close__size: rem-calc( 24 ) !default;
$icon__close__color: $black-ter !default;;
$icon__close__opacity: 0.5 !default;;
$icon__close__opacity--hover: 1 !default;;

.icon-hamburger{
  @include icon-hamburger(
      $icon__hamburger__stroke-height,
      $icon__hamburger__width,
      $icon__hamburger__background--color,
      rem-calc(7)
  )
}
.icon-close{
  @include single-transition(opacity);
  position: relative;
  display: inline-block;
  width: $icon__close__size;
  height: $icon__close__size;
  overflow: hidden;
  opacity: $icon__close__opacity;
  &:before,
  &:after{
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: $icon__close__color;
  }
  &:before{
    transform: rotate(45deg);
  }
  &:after{
    transform: rotate(-45deg);
  }
  &:hover{
    opacity: $icon__close__opacity--hover;
  }
}

@font-face {
  font-family: 'tenenzIcons';
  src:url('#{$base__font-path}tenenzIcons_2.eot');
  src:url('#{$base__font-path}tenenzIcons_2.eot?#iefix') format('embedded-opentype'),
  url('#{$base__font-path}tenenzIcons_2.woff') format('woff'),
  url('#{$base__font-path}tenenzIcons_2.ttf') format('truetype'),
  url('#{$base__font-path}tenenzIcons_2.svg#tenenzIcons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-more,
.icon-right,
.icon-left,
.icon-up,
.icon-down,
.icon-dashboard,
.icon-users,
.icon-user-info,
.icon-cart,
.icon-reports,
.icon-database,
.icon-trash,
.icon-user,
.icon-home,
.icon-actions,
.icon-envelope,
.icon-action,
.icon-caution,
.icon-file,
.icon-catalog,
.icon-search,
.icon-delete,
.icon-remove,
.icon-wrench,
.icon-edit-page,
.icon-chevron-right,
.icon-chevron-up,
.icon-chevron-down,
.icon-chevron-left,
.icon-orders,
.icon-promotions,
.icon-approval,
.icon-edit,
.icon-start,
.icon-pause,
.icon-power,
.icon-add,
.icon-zoom-out,
.icon-zoom-in,
.icon-required,
.icon-warning,
.icon-success,
.icon-info,
.icon-pdf,
.icon-small-list,
.icon-large-list,
.icon-facebook,
.icon-twitter
{
  @include icon-font-defaults();
}
.icon{
  &--collapse{
    margin-right: -.275em;
    margin-left: -.275em;
  }
  &--collapse-right{
    margin-right: -.275em;
  }
  &--collapse-left{
    margin-left: -.275em;
  }
}
.icon-more:before {
  content: "\e800";
}
.icon-right:before {
  content: "\e01c";
}
.icon-left:before {
  content: "\e001";
}
.icon-up:before {
  content: "\e002";
}
.icon-down:before {
  content: "\e003";
}
.icon-dashboard:before {
  content: "\e004";
}
.icon-users:before {
  content: "\e006";
}
.icon-user-info:before {
  content: "\e007";
}
.icon-cart:before {
  content: "\e008";
}
.icon-reports:before {
  content: "\e009";
}
.icon-database:before {
  content: "\e00a";
}
.icon-trash:before {
  content: "\e00b";
}
.icon-user:before {
  content: "\e00c";
}
.icon-home:before {
  content: "\e00d";
}
.icon-actions:before {
  content: "\e00e";
}
.icon-envelope:before {
  content: "\e00f";
}
.icon-action:before {
  content: "\e010";
}
.icon-caution:before {
  content: "\e011";
}
.icon-file:before {
  content: "\e012";
}
.icon-catalog:before {
  content: "\e013";
}
.icon-search:before {
  content: "\e014";
}
.icon-delete:before {
  content: "\e015";
}
.icon-remove:before {
  content: "\e016";
}
.icon-wrench:before {
  content: "\e018";
}
.icon-edit-page:before {
  content: "\e019";
}
.icon-chevron-right:before {
  content: "\e01a";
}
.icon-chevron-up:before {
  content: "\e027";
}
.icon-chevron-down:before {
  content: "\e028";
}
.icon-chevron-left:before {
  content: "\e01b";
}
.icon-orders:before {
  content: "\e000";
}
.icon-promotions:before {
  content: "\e005";
}
.icon-approval:before {
  content: "\e01d";
}
.icon-edit:before {
  content: "\e017";
}
.icon-start:before {
  content: "\e01e";
}
.icon-pause:before {
  content: "\e01f";
}
.icon-power:before {
  content: "\e020";
}
.icon-add:before {
  content: "\e021";
}
.icon-zoom-out:before {
  content: "\e022";
}
.icon-zoom-in:before {
  content: "\e023";
}
.icon-required:before {
  content: "\e024";
}
.icon-warning:before {
  content: "\e025";
}
.icon-success:before {
  content: "\e026";
}
.icon-info:before {
  content: "\e600";
}
.icon-pdf:before {
  content: "\e601";
}
.icon-small-list:before {
  content: "\e801";
}
.icon-large-list:before {
  content: "\e802";
}
.icon-facebook:before {
  content: "\e900";
}
.icon-twitter:before {
  content: "\e901";
}

.icon--success{
  color: $success;
}
.icon--danger{
  color: $danger;
}
.icon--required-color{
  color: $mustard;
}

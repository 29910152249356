$testimonial__quote__font-family: null !default;
$testimonial__quote__font-style: null !default;
$testimonial__quote__font-size: null !default;
$testimonial__quote__font-weight: null !default;
$testimonial__quote__line-height: null !default;
$testimonial__quote__color: null !default;
$testimonial__quote__border-left-color: null !default;
$testimonial__quote__border-left: null !default;
$testimonial__quote__padding: null !default;
$testimonial__quote__margin: null !default;
$testimonial__quote__mark__font-family: null !default;
$testimonial__quote__mark__font-weight: null !default;
$testimonial__quote__mark__font-style: null !default;
$testimonial__quote__mark__font-size: null !default;
$testimonial__quote__mark__line-height: null !default;
$testimonial__author__color: null !default;
$testimonial__author__font-weight: null !default;

.content .testimonial,
.testimonial{
  &__quote{
    margin: $testimonial__quote__margin;
    font-family: $testimonial__quote__font-family;
    font-size: nth($testimonial__quote__font-size, 1);
    font-weight: $testimonial__quote__font-weight;
    font-style: $testimonial__quote__font-style;
    line-height: nth($testimonial__quote__line-height, 1);
    color: $testimonial__quote__color;
    border-left: $testimonial__quote__border-left;
    padding: $testimonial__quote__padding;
    background-color: transparent;
  }
  &__quote__mark{
    font-family: $testimonial__quote__mark__font-family;
    font-weight: $testimonial__quote__mark__font-weight;
    font-style: $testimonial__quote__mark__font-style;
    font-size: $testimonial__quote__mark__font-size;
    line-height: $testimonial__quote__mark__line-height;
  }
  &__author{
    padding-right: rem-calc( $gap / 2 );
    text-align: right;
    color: $testimonial__author__color;
    font-weight: $testimonial__author__font-weight;
  }
}

@include tablet{
  .content .testimonial,
  .testimonial {
    &__quote {
      font-size: nth($testimonial__quote__font-size, 2);
      line-height: nth($testimonial__quote__line-height, 2);
    }
  }
}

@include desktop{
  .content .testimonial,
  .testimonial {
    &__quote {
      font-size: nth($testimonial__quote__font-size, 3);
      line-height: nth($testimonial__quote__line-height, 3);
    }
  }
}

.footer{
  border-top: 1px solid $grey-lighter;
  background: $white;
}

.footer__main__nav__list,
.footer__social__nav__list,
.footer__legal__nav__list {
  list-style: none;
  display: block;
  align-items: center;
  justify-content: center;
  &-item__link{
    display: block;
    padding: rem-calc( $gap / 4 ) rem-calc( $gap / 2 );
  }
}
.footer__address__list{
  display: table;
  margin: auto;
  list-style: none;
  &-item{
    padding: rem-calc( $gap / 4 );
  }
}

@include tablet{
  .footer__main__nav__list,
  .footer__social__nav__list,
  .footer__legal__nav__list{
    display: flex;
  }
  .footer__address__list{
    &-item{
      display: table-cell;
      padding: rem-calc( $gap / 2 );
    }
  }
}
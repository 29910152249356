.pricing__section{
  background: $white;
  margin: rem-calc( $gap * 2 ) 0;
  padding: rem-calc( $gap * 2 ) rem-calc( $gap / 2 );
  border-top: 1px solid $grey-lighter;
  border-bottom: 1px solid $grey-lighter;
  //margin-bottom: rem-calc( $gap );
}

.pricing__content{
  padding: rem-calc( $gap / 2 );
  &--white-bg{
    @include shadows(1);
    background: $white;
    border-radius: $base__border__radius;
  }
}

.pricing__table__icon{
  &--check{
    width: rem-calc(18);
  }
}

.pricing__table__title{
  color: $blue-dark;
}

table.pricing{
  .forms-group.start{
    border-left: 1px solid #AFAFAF;
  }
  td.forms-group.start {
    border-right: 1px solid #EEEEEE;
  }
  thead th {
    text-align: center;
  }
}
.header{
  position: fixed;
  width: 100%;
  background: rgba(255,255,255, 0.8);
  z-index: 2;
  @include shadows(1);
  &__navbar{
    background-color: transparent;
  }
  &__navbar__brand{
  }
  &__navbar__brand__logo{
    width: rem-calc(250);
    @at-root a.header__navbar__brand__logo{
      &:hover,
      &:focus{
        background-color: transparent;
      }
    }
  }
  @at-root img.header__navbar__brand__logo__img{
    max-height: none;
  }
  &__navbar__brand__open-nav__link{
    display: flex;
    align-items: center;
    width: $icon__hamburger__width + rem-calc($gap / 2);
    margin-left: auto;
    margin-right: rem-calc($gap/2);
    padding: rem-calc($gap / 4);
  }
}

@include desktop{
  .header{
    &__navbar__brand__open-nav__link{
      display: none;
    }
  }
}
.intro {
  margin: rem-calc( $gap ) auto;
  padding: 0 rem-calc( $gap / 2 );
  text-align: center;
  &--top-padded{
    padding-top: rem-calc( $gap );
    margin: 0 auto rem-calc( $gap ) auto;
  }
}

@include tablet{
  .intro {
    margin: rem-calc( $gap * 1.5 ) auto;
    &--top-padded{
      margin: 0 auto rem-calc( $gap * 1.5 ) auto;
    }
  }
}

@include desktop{
  .intro {
    margin: rem-calc( $gap * 2 ) auto;
    &--top-padded{
      margin: 0 auto rem-calc( $gap * 2 ) auto;
    }
  }
}
.guide-to-info-returns__table{
  &__header{
    &__row{
      background: $grey-lighter;
      @at-root .content table tr.guide-to-info-returns__table__header__row{
        background: $grey-lighter;
        &:hover{
          background: $grey-lighter;
        }
      }
    }
    &__cell{
      padding: rem-calc(4);
      @at-root .content table .guide-to-info-returns__table__header__cell{
        border-color: $grey-light;
      }
    }
  }
  &__row{
  }
  &__cell{
    border: 1px solid #cccccc;
    padding: 4px;
    font-size: rem-calc(14);
    &--form-name{
      font-weight: bold;
      white-space: nowrap;
    }
  }
}

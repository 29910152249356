.features {
  margin: rem-calc( $gap ) 0;
}

@include tablet{
  .features {
    margin: rem-calc( $gap * 1.5 ) 0;
  }
}

@include desktop{
  .features {
    margin: rem-calc( $gap * 2 ) 0;
  }
}
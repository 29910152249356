// Headings
h1,
h2,
h3,
h4,
h5,
h6{
  text-rendering: $base__heading__text-rendering;
  &.is-marginless{
    margin-bottom: 0;
  }
  &.is-vcentered{
    display: flex;
    align-items: center;
  }
  &.is-centered{
    justify-content: center;
  }
  small {
    font-size: $base__small__font-size;
    line-height: 0;
  }
}
h1, h2, h3{
  margin-bottom: nth($base__heading__margin-bottom, 1);
  &.has-less-margin{
    margin-bottom: nth($base__heading__margin-bottom, 1) / 2;
  }
}
h4, h5, h6{
  margin-bottom: nth($base__heading__margin-bottom, 2);
  &.has-less-margin{
    margin-bottom: nth($base__heading__margin-bottom, 2) / 2;
  }
}

h1 {
  font-size: nth($h1__font-sizes, 1);
  font-style: $h1__font-style;
  font-weight: $h1__font-weight;
  line-height: nth($h1__line-height, 1);
}
h2 {
  font-size: nth($h2__font-sizes, 1);
  line-height: nth($h2__line-height, 1);
  font-style: $h2__font-style;
  font-weight: $h2__font-weight;
}
h3 {
  font-size: nth($h3__font-sizes, 1);
  line-height: nth($h3__line-height, 1);
  font-style: $h3__font-style;
  font-weight: $h3__font-weight;
}
h4 {
  font-size: nth($h4__font-sizes, 1);
  line-height: nth($h4__line-height, 1);
  font-style: $h4__font-style;
  font-weight: $h4__font-weight;
}
h5 {
  font-size: nth($h5__font-sizes, 1);
  line-height: nth($h5__line-height, 1);
  font-style: $h5__font-style;
  font-weight: $h5__font-weight;
}
h6 {
  font-size: nth($h6__font-sizes, 1);
  line-height: nth($h6__line-height, 1);
  font-style: $h6__font-style;
  font-weight: $h6__font-weight;
}

p{
  font-size: nth($base__paragraph__font-size, 1);
  margin-bottom: nth( $base__paragraph__margin-bottom, 1 );
  line-height: nth($base__paragraph__line-height, 1);
  text-rendering: $base__paragraph__text-rendering;
  &.is-marginless{
    margin-bottom: 0;
  }
  &.has-less-margin{
    margin-bottom: nth( $base__paragraph__margin-bottom, 1 ) / 2;
  }
  &.has-more-margin{
    margin-bottom: nth( $base__paragraph__margin-bottom, 2 );
  }
  &.has-large-margin{
    margin-bottom: nth( $base__paragraph__margin-bottom, 3 );
  }
}

a{
  color: $anchor-color;
  &:hover,
  &:focus{
    color: $anchor-color-hover;
  }
}

em{
  font-style: italic;
  line-height: inherit;
}

strong{
  font-weight: $base__font-weight--bold;
  line-height: inherit;
}

small {
  font-size: $base__small__font-size;
  line-height: inherit;
}

@include tablet{
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  }
  h1 {
    font-size: nth($h1__font-sizes, 2);
    line-height: nth($h1__line-height, 2);
  }
  h2 {
    font-size: nth($h2__font-sizes, 2);
    line-height: nth($h2__line-height, 2);
  }
  h3 {
    font-size: nth($h3__font-sizes, 2);
    line-height: nth($h3__line-height, 2);
  }
  h4 {
    font-size: nth($h4__font-sizes, 2);
    line-height: nth($h4__line-height, 2);
  }
  h5 {
    font-size: nth($h5__font-sizes, 2);
    line-height: nth($h5__line-height, 2);
  }
  h6 {
    font-size: nth($h6__font-sizes, 2);
    line-height: nth($h6__line-height, 2);
  }
  p{
    line-height: nth( $base__paragraph__line-height, 2);
  }
}

@include desktop{
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  }
  h1 {
    font-size: nth($h1__font-sizes, 3);
    line-height: nth($h1__line-height, 3);
  }
  h2 {
    font-size: nth($h2__font-sizes, 3);
    line-height: nth($h2__line-height, 3);
  }
  h3 {
    font-size: nth($h3__font-sizes, 3);
    line-height: nth($h3__line-height, 3);
  }
  h4 {
    font-size: nth($h4__font-sizes, 3);
    line-height: nth($h4__line-height, 3);
  }
  h5 {
    font-size: nth($h5__font-sizes, 3);
    line-height: nth($h5__line-height, 3);
  }
  h6 {
    font-size: nth($h6__font-sizes, 3);
    line-height: nth($h6__line-height, 3);
  }
  p{
    line-height: nth( $base__paragraph__line-height, 3);
  }
}

@include widescreen{
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  }
  h1 {
    font-size: nth($h1__font-sizes, 4);
    line-height: nth($h1__line-height, 4);
  }
  h2 {
    font-size: nth($h2__font-sizes, 4);
    line-height: nth($h2__line-height, 4);
  }
  h3 {
    font-size: nth($h3__font-sizes, 4);
    line-height: nth($h3__line-height, 4);
  }
  h4 {
    font-size: nth($h4__font-sizes, 4);
    line-height: nth($h4__line-height, 4);
  }
  h5 {
    font-size: nth($h5__font-sizes, 4);
    line-height: nth($h5__line-height, 4);
  }
  h6 {
    font-size: nth($h6__font-sizes, 4);
    line-height: nth($h6__line-height, 4);
  }
  p{
    line-height: nth( $base__paragraph__line-height, 4);
  }
}

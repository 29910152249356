//$hero__info__gradient__to-right__background: linear-gradient(to right, rgba(72, 158, 217, .4), rgba(73, 158, 217, .8)) !default;
//$hero__info__gradient__to-left__background: linear-gradient(to left, rgba(72, 158, 217, .4), rgba(73, 158, 217, .8)) !default;
$hero__info__gradient__to-right__background: linear-gradient(to right, rgba($info, .4), rgba($info, .8)) !default;
$hero__info__gradient__to-left__background: linear-gradient(to left, rgba($info, .4), rgba($info, .8)) !default;
$hero__info-dark__gradient__to-right__background: linear-gradient(to right, rgba($info-dark, .4), rgba($info-dark, .8)) !default;
$hero__info-dark__gradient__to-left__background: linear-gradient(to left, rgba($info-dark, .4), rgba($info-dark, .8)) !default;
$hero__info-light__gradient__to-right__background: linear-gradient(336deg, rgba(255,255,255,1) 0%, rgba(203,236,255,1) 64%, rgba(118,187,227,1) 100%) !default;
$hero__info-light__gradient__to-left__background: linear-gradient(43deg, rgba(255,255,255,1) 0%, rgba(203,236,255,1) 64%, rgba(118,187,227,1) 100%) !default;
$hero__info-light__background: rgba(224, 244, 255, .35);
$hero__info-lighter__background: rgba(224, 244, 255, 0.65);
$hero__info-dark__background: rgba(49, 83, 187, .25);

.hero{
  overflow: hidden;
    border-bottom: 1px solid $grey-lighter;
  &.is-info{
      color: $grey-dark;
    &.is-bold{
      $gradient-top-left: #36368f;
      $gradient-bottom-right: #1e2373;
      //background-image: linear-gradient(141deg, $gradient-top-left 0%, #39438b 71%, $gradient-bottom-right 100%);
      background-color: #cce8ef;
      background-image: $hero__info-light__gradient__to-right__background;
    }
  }
}
.hero.video{
  position: relative;
}
.hero-video{
  opacity: 0;
}
.hero-body{
  max-width: rem-calc(1300);
  margin: auto;
  padding: rem-calc( $gap ) rem-calc( $gap / 2 );
}
.hero-body .container {
  max-width: rem-calc(700);
  margin: auto;
  &--full-width{
    max-width: none;
    width: auto;
  }
}
.hero-body .hero-title {
  //@include text-shadow(2, 'text-shadow');
  color: #2a3690;
}
.hero-body .hero-subtitle {
  color: $grey-dark;
}
.hero-subtitle--large{
  font-size: rem-calc(18);
}
.hero-body .container {
  padding: rem-calc( $gap / 2 );
  border-radius: 10px;
  &--is-left{
    margin-left: 0;
  }
  &--is-right{
    margin-right: 0;
  }
}

.hero-column__img__column{
  display: none;
  &--how-to-e-file,
  &--help-and-faq{
    max-width: rem-calc(325);
  }
  &--forms-and-pricing{
    max-width: rem-calc(350);
  }
  &--contact-us,
  &--tax-resources{
    max-width: rem-calc(425);
  }
}


.is-info .hero-body .container{
  &--is-left--widescreen{
    background: $hero__info-light__background;
  }
  &--is-right--widescreen{
    background: $hero__info-light__background;
  }
  &--has-bg-color-dark{
    background: rgba($info-dark, .6);
  }
  &--has-bg-lighter{
    background: $hero__info-lighter__background;
  }
}

@include tablet{
  .hero-body{
    padding: rem-calc( $gap * 1.2 ) rem-calc( $gap / 2 );
  }
  .is-info .hero-body .container{
    &--is-left--tablet{
      background: $hero__info-light__background;
    }
    &--is-right--tablet{
      background: $hero__info-light__background;
    }
  }
  .hero-body .container {
    &--is-left--tablet{
      margin-left: 0;
    }
    &--is-right--tablet{
      margin-right: 0;
    }
  }
}

@include desktop{
  .hero-body{
    padding: rem-calc( $gap * 1.5 ) rem-calc( $gap / 1.5 );
  }
  .is-info .hero-body .container{
    &--is-left--desktop{
      background: $hero__info-light__background;
    }
    &--is-right--desktop{
      background: $hero__info-light__background;
    }
      &--has-bg-light--desktop{
          background: $hero__info-light__background;
      }
  }
  .hero-subtitle--large{
    font-size: rem-calc(20);
  }
  .hero-body .container {
    &--is-left--desktop{
      margin-left: 0;
    }
    &--is-right--desktop{
      margin-right: 0;
    }
  }
  .hero-body__has-columns{
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
  }
  .hero-container__has-columns{
    display: flex;
    align-items: center;
  }
  .hero-column__content{

  }
  .hero-column__img__column{
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    align-self: flex-end;
    display: block;
  }
  .hero-column__img{
  }
}

@include widescreen{
  .is-info .hero-body .container{
    &--is-left--tablet,
    &--is-right--tablet,
    &--is-left--desktop,
    &--is-right--desktop,
    {
      background: transparent;
    }
  }
  .hero-body .container{
    padding: 0;
    border-radius: 0;
    &--has-bg-color-dark, &--has-bg-lighter, &--has-bg-light--desktop{
      padding: rem-calc( $gap / 2 );
      border-radius: 10px;
    }
    &--is-left--widescreen{
      margin-left: 0;
    }
    &--is-right--widescreen{
      margin-right: 0;
    }
  }
}

.has-border-top{
  border-top: 1px solid $grey-lighter;
}
.has-border-bottom{
  border-bottom: 1px solid $grey-lighter;
}
.has-margin-bottom,
.columns:not(:last-child).has-margin-bottom{
  margin-bottom: rem-calc( $gap );
}
.has-padding-top{
  padding-top: rem-calc( $gap );
}
.has-text-underlined{
  text-decoration: underline;
}
.has-white-bg{
  background: $white;
  padding: rem-calc( $gap ) rem-calc( $gap / 2 );
  border: 1px solid $grey-lighter;
}
.has-border-radius{
  border-radius: $base__border__radius;
}
.has-side-margins-auto{
  margin-right: auto;
  margin-left: auto;
}
.has-flex-dir-column-reverse--mobile{
  flex-direction: column-reverse;
}
.has-position{
  position: relative;
}

@include tablet{
  .has-flex-dir-column-reverse--mobile{
    flex-direction: row;
  }
}
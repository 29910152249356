.section{
  padding: rem-calc( $gap ) rem-calc( $gap / 2 );
  &--is-white{
    background: $white;
    padding: rem-calc( $gap * 2 ) rem-calc( $gap / 2 );
    border-top: 1px solid $grey-lighter;
    border-bottom: 1px solid $grey-lighter;
  }
  &--bordered{
    border: 1px solid $grey-lighter;
  }
  &--radius{
    border-radius: $base__border__radius;
  }
}

@include tablet{
  .section{
    padding: rem-calc( $gap * 1.2 ) rem-calc( $gap / 2 );
  }
}

@include desktop{
  .section{
    padding: rem-calc( $gap * 1.5 ) rem-calc( $gap / 1.5 );
  }
}
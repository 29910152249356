.container{
  &--is-white{
    background: $white;
    padding: rem-calc( $gap * 2 ) rem-calc( $gap / 2 );
    border-top: 1px solid $grey-lighter;
    border-bottom: 1px solid $grey-lighter;
  }
  &--bordered{
    border: 1px solid $grey-light;
  }
  &--radius{
    border-radius: $base__border__radius;
  }
}
.column{
  &--collapse{
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  &--is-white{
    background: $white;
  }
  &--radius{
    border-radius: $base__border__radius;
  }
}
.is-padded{
  padding: rem-calc( $gap / 2 );
}
.is-always-half{
  width: 50%;
}

.steps__section{
  margin: rem-calc( $gap * 2 ) 0;
  padding: 0 rem-calc( $gap );
  margin-bottom: rem-calc( $gap );
  &--use-padding{
    margin-top: 0;
    margin-bottom: 0;
    padding-top: rem-calc( $gap ) * 2;
    padding-bottom: rem-calc( $gap );
  }
  &--is-white{
    background: $white;
  }
}
.step{
  @extend .box;
  padding-top: rem-calc( $gap );
  &:not(:last-child){
    margin-bottom: rem-calc( $gap * 2 );
  }
  &__title{
    &--minimum-margin:not(:last-child){
      margin-bottom: rem-calc(6);
    }
  }
  &__img__column{
    flex: 0 0 rem-calc(100);
    text-align: center;
  }
  &__content__column{

  }
}
.video--full-width{
  width: 100%;
}
.video--radius{
  border-radius: $base__border__radius;
}
.video__description{
  &__content{
    padding: rem-calc( $gap/2 );
  }
  &__title{

  }
}
.video__responsive{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
  border: 1px solid $grey-lighter;
  border-radius: $base__border__radius;
  & iframe,
  & > video{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
  }
  &--max-w-h--560-315{
    max-width: 560px;
    max-height: 315px;
  }
  &__container{
    margin-bottom: rem-calc( $gap / 2 );
  }
}

@include tablet {
  .video__description {
    &__content {
      padding: rem-calc($gap/2) rem-calc($gap) rem-calc($gap/2) 0;
    }
    &__title {

    }
  }
}
.buy-downs{
  &__list{

  }
  &__list__item{
    margin: rem-calc( $gap / 2 ) auto;
    border-bottom: 1px solid $grey-light;
    padding-bottom: rem-calc( $gap / 2 );
    &:last-child{
      border-bottom: none;
    }
  }
  &__list__item__heading{
    color: $black;
    text-align: center;
    margin-bottom: rem-calc( $gap / 4 );
  }
  &__list__item__heading__highlight{
    display: inline-block;
    border: 1px solid $mustard;
    border-radius: 3px;
    padding-left: rem-calc( $gap / 4 );
    padding-right: rem-calc( $gap / 4 );
  }
  &__comparison{
    &__row{
      //@include clearfix();
      display: flex;
      justify-content: center;
      text-align: center;
    }
    &__col{
      display: inline-block;
      vertical-align: top;
      margin: 0 rem-calc( $gap / 2 );
      &--highlight{
        background: $cream;
        border: 1px solid $mustard;
        border-radius: $base__border__radius;
        padding-left: rem-calc( $gap / 3 );
        padding-right: rem-calc( $gap / 3 );
        text-align: center;
      }
      &__heading{
        text-align: center;
        margin-bottom: rem-calc( $gap / 4 );
      }
      &__table-cell--highlight{
        border: 2px solid $mustard;
      }
      &--savings{
        align-self: center;
      }
    }
  }
  &__table{
    @at-root .table th.buy-downs__table__th--right-border{
      border-right-color: $grey-light;
    }
  }
}
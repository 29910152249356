.box{
  @include shadows(1);
  &.cta{
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
  &--minimal-padding{
    padding-top: rem-calc( $gap / 3 );
    padding-bottom: rem-calc( $gap / 3 );
  }
  &__efile-provider__badge{
    &__container{
      display: block;
      margin: auto;
      vertical-align: middle;
      width: rem-calc(125);
    }
  }
}

@include desktop{
  .box{
    &__efile-provider__badge{
      &__container{
        display: inline-block;
      }
    }
  }
}
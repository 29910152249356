.toggle{
  &__section{
    padding: rem-calc( $gap );
    border: 1px solid $grey-light;
    border-radius: $base__border__radius;
    background: $smoke;
    margin-bottom: rem-calc( $gap );
  }
  &--is-visible{

  }
  &__visibility{
    @include element-invisible();
    @include single-transition(all, 600ms);
    @at-root .toggled .toggle__visibility{
      @include element-invisible-off();
    }
  }
  &__icon{
    @include single-transition( transform, 150ms );
    display: inline-block;
    transform: rotate(0);
    @at-root .toggled .toggle__icon--180{
      transform: rotate(180deg);
    }
  }
}

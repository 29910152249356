//shadows
@mixin shadows($depth, $type: "box-shadow", $position: null) {
  @if $depth < 1 {
    #{$type}: none;
  } @else if $depth > 5 {
    @warn "Invalid $depth `#{$depth}` for mixin `card`.";
  } @else {
    #{$type}: bottom-shadow($depth), top-shadow($depth);
  }
}
@mixin text-shadow($depth, $position: null) {
  @if $depth < 1 {
    text-shadow: none;
  } @else if $depth > 5 {
    @warn "Invalid $depth `#{$depth}` for mixin `card`.";
  } @else {
    text-shadow: bottom-shadow($depth), top-shadow($depth);
  }
}

@mixin icon-font-defaults(
  $font-size: 2.3em,
  $font-family: 'tenenzIcons',
  $line-height: 0
){
  font-family: $font-family;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: $line-height;
  font-size: $font-size;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin icon-hamburger(
  $stroke-height: $icon__hamburger__stroke-height,
  $width: $icon__hamburger__width,
  $background: $icon__hamburger__background--color,
  $stroke-spacing: null
){
  @if($stroke-spacing == null){
    $stroke-spacing: $stroke-height * 2.25;
  }
  @else{
    $stroke-spacing: $stroke-height + $stroke-spacing;
  }
  &, &:before, &:after {
    position: absolute;
    cursor: pointer;
    border-radius: 1px;
    height: $stroke-height;
    width: $width;
    background: $background;
    display: block;
    content: '';
    transition: transform 300ms ease-out, background 300ms ease-out, top 300ms ease-out;
  }
  &:before{
    top: - $stroke-spacing;
  }
  &:after{
    bottom:  - $stroke-spacing;
  }
}

@mixin icon-hamburger--close($prefix-class: "--open"){
  &#{$prefix-class}{
    background: transparent;
  }
  &#{$prefix-class}:before, &#{$prefix-class}:after{
    top: 0;
  }
  &#{$prefix-class}:before{
    transform: rotate(45deg);
  }
  &#{$prefix-class}:after{
    transform: rotate(-45deg);
  }
}

@mixin icon-close(
  $stroke-height: $icon__hamburger__stroke-height,
  $width: $icon__hamburger__width,
  $background: $icon__hamburger__background--color,
  $top-position: 0
){
  &:before, &:after {
    position: absolute;
    top: $top-position;
    cursor: pointer;
    border-radius: 1px;
    height: $stroke-height;
    width: $width;
    background: $background;
    display: block;
    content: '';
    transition: transform 300ms ease-out, background 300ms ease-out, top 300ms ease-out;
  }
  &:before{
    transform: rotate(45deg);
  }
  &:after{
    transform: rotate(-45deg);
  }
}

@mixin single-transition($property, $speed: $base__transition-speed, $ease: $base__transition-ease) {
  transition: $property $speed $ease;
}

// Turns on the element-invisible effect.
@mixin element-invisible($important: null) {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  @if($important){
    position: absolute !important;
  }
  @else{
    position: absolute;
  }
  width: 1px;
}
// Turns off the element-invisible effect.
@mixin element-invisible-off($important: null, $position: static, $width: auto, $height: auto, $overflow: visible) {
  @if($important){
    position: $position !important;
  }
  @else{
    position: $position;
  }
  height: $height;
  width: $width;
  overflow: $overflow;
  clip: auto;
}

$form__label__color: $grey-dark !default;
$form__label__font-size: rem-calc(14) !default;
$form__label__line-height: 1.5 !default;
$form__label__required__color: $required !default;
$form__label__required__content: "\e024" !default;
$form__input__font-size: rem-calc(18) !default;
$form__input__border-color: $grey-lighter !default;
$form__input__border: 1px solid $form__input__border-color !default;
$form__input__border-radius: $base__border__radius !default;
$form__input__border-color--focus: $grey-light !default;
$form__input__border--focus: 1px solid $form__input__border-color--focus !default;
$form__input__box-shadow--focus: 0 0 5px rgba(150,150,150, .35) !default;
$form__required-icon__content: "\e024" !default;
$form__required-icon__color: $required !default;
$form__input-list-item__margin-bottom: rem-calc(16) !default;
$form__error__padding: rem-calc(6) rem-calc(9) !default;
$form__error__font-size: rem-calc(12) !default;
$form__error__font-weight: normal !default;
$form__error__font-style: italic !default;
$form__error__color: #ffffff !default;
$form__error__background-color: $warning !default;
$form__error__border-radius: $base__border__radius !default;
$form__toggle__on__background-color: $teal !default;
$form__toggle__border-color: #000000 !default;

form{
  margin: 0 0 rem-calc(16);
}

fieldset{
  // override normalize settings
  border: none;
  padding-right: 0;
  padding-left: 0;
}

.form__fieldset--collapse{
  padding-top: 0;
  padding-bottom: 0;
}

legend{
  margin: 0;
  font-weight: bold;
}
.form--collapse{
  margin: 0;
}
.form__input-list {
  list-style: none;
  margin-left: 0;
  &-item{
    margin-bottom: $form__input-list-item__margin-bottom;
    padding: 0;
    &__row{
      @include clearfix();
    }
    &__description{
      margin-bottom: $form__input-list-item__margin-bottom;
      padding: 0;
      border-bottom: 1px solid #eeeeee;
    }
    &--collapse{
      margin: 0;
    }
  }
}
label{
  font-size: $form__label__font-size;
  color: $form__label__color;
  cursor: pointer;
  display: block;
  font-weight: normal;
  line-height: $form__label__line-height;
  margin-bottom: 0;
}
.label__required{
  &:after {
    @include icon-font-defaults(
            $font-size: 1.5em
    );
    content: $form__label__required__content;
    color: $form__label__required__color;
  }
}

.form__label{
  &--inline{
    display: inline-block;
    vertical-align: middle;
  }
  &--required{
    &:after{
      @include icon-font-defaults($font-size: 1.5em);
      content: $form__required-icon__content;
      color: $form__required-icon__color;
    }
  }
}
input, select, button, textarea{
  //font-family: inherit; //this is set by normalize
  font-size: $form__input__font-size;
  border-radius: $form__input__border-radius;
}

.form__input,
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea{
  -webkit-appearance: none;
  border: $form__input__border;
  padding: rem-calc(8);
  width: 100%;
  box-sizing: border-box;
  &:focus{
    outline: none;
    border: $form__input__border--focus;
    box-shadow: $form__input__box-shadow--focus;
  }
}

input[type="checkbox"],
input[type="radio"]{

}

select{
  -webkit-appearance: none;
  border: $form__input__border;
  padding: rem-calc(6) rem-calc(8) rem-calc(8) rem-calc(8);
  height: rem-calc(40);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGJhc2VQcm9maWxlPSJ0aW55IiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIKCSB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQ1cHgiIGhlaWdodD0iMzkuNzVweCIgdmlld0JveD0iMTMgMTUuNSA0NSAzOS43NSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+Cjxwb2x5Z29uIGZpbGw9IiM1NTU1NTUiIHBvaW50cz0iMzYuMTA1LDQwLjU1OSAyNy44NzEsMzEuNDQyIDQ0LjM0MiwzMS40NDIgMzYuMTA1LDQwLjU1OSAyNy44NzEsMzEuNDQyIDQ0LjM0MiwzMS40NDIgIi8+Cjwvc3ZnPg==);
  background-position: 100% center;
  background-repeat: no-repeat;
  //to correct firefox issues ...
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

textarea{
  //max-width: 100%;
}

.form__input--flat-right{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.form__note{
  font-size: rem-calc(12.5);
}

.form__error{
  display: block;
  padding: $form__error__padding;
  margin-top: - rem-calc(1);
  font-size: $form__error__font-size;
  font-weight: $form__error__font-weight;
  font-style: $form__error__font-style;
  background: $form__error__background-color;
  color: $form__error__color;
  border-radius: $form__error__border-radius;
}

input.is-danger,
input:focus.is-danger{
    border: 1px solid $danger;
}

.form__toggle{
  @include clearfix();
  vertical-align: middle;
  &__checkbox__input{
    display:none;
    &:checked + .form__toggle__checkbox__label{
      background: $form__toggle__on__background-color;
      &:before{
        left:18px;
      }
    }
  }
  &__checkbox__label{
    width:40px;
    height:21px;
    box-sizing:border-box;
    border:1px solid;
    float:left;
    border-radius:50px;
    position:relative;
    cursor:pointer;
    transition:.3s ease;
    &:before{
      transition:.3s ease;
      content:'';
      width:21px;
      height:21px;
      position:absolute;
      background:white;
      left:-1px;
      top:-1px;
      box-sizing:border-box;
      border:1px solid;
      color: $form__toggle__border-color;
      border-radius:50px;
    }
  }
}

@include desktop {
  .form__input{
    &-column {
      float: left;
      &__fname,
      &__lname,
      &__state,
      &__zip,
      &__phone,
      &__phone-ext{
        //@include grid-column(6, $padding: false);
      }
      &__fname,
      &__state,
      &__phone{
        padding-right: $gap / 2;
      }
      &__lname,
      &__zip,
      &__phone-ext{
        padding-left: $gap / 2;
      }
    }
  }
}

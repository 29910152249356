html, body {
  //height: 100%;
}

.is-order-1--on-small{
  order: 1;
}
.is-order-2--on-small{
  order: 2;
}

@include tablet{
  .is-order-1--on-small,
  .is-order-2--on-small{
    order: 0;
  }
}

.table{
  &.is-striped thead{
    background: $grey-lighter;
  }
  tbody.center-align td {
    text-align: center;
  }
  tbody.right-align td {
    text-align: right;
    padding-right: rem-calc(10);
  }
}
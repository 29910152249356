$help__category__faq__question__link__font-size: rem-calc(17.5), rem-calc(18), rem-calc(20), rem-calc(20);

.help{
  &__category{
    @include element-invisible();
    padding-left: rem-calc( $gap / 2 );
    padding-right: rem-calc( $gap / 2 );
    &--show-category{
      @include element-invisible-off();
      margin-bottom: rem-calc($gap);
    }
  }
  &__category__link{

  }
  &__category__title{

  }
  &__category__faq{
    @include single-transition(padding);
    border-bottom: 1px solid $grey-lighter;
    padding: 0;
    &:last-child{
      border-bottom: none;
    }
    &--show-answer{
      background: $smoke;
      border: 1px solid $grey-lighter;
      border-radius: $base__border__radius;
      padding: rem-calc( $gap / 2 );
    }
  }
  &__category__faq__title{

  }
  &__category__faq__question{
    margin: 0;
  }
  &__category__faq__question__link{
    display: block;
    padding: rem-calc( $gap / 3 ) 0;
    font-size: nth($help__category__faq__question__link__font-size, 1);
  }
  &__category__faq__answer{
    @include element-invisible();
    @at-root .help__category__faq--show-answer &{
      @include element-invisible-off();
    }
  }
}

@include tablet{
  .help{
    &__category__faq__question__link{
      font-size: nth($help__category__faq__question__link__font-size, 2);
    }
  }
}

@include desktop{
  .help{
    &__category__faq__question__link{
      font-size: nth($help__category__faq__question__link__font-size, 3);
    }
  }
}
$off-canvas__container__open__position: null !default;
$off-canvas__container__open__z-index: null !default;
$off-canvas__container-overlay__background-color: #000 !default;
$off-canvas__container-overlay__background-opacity: .3 !default;
$off-canvas__container-overlay__box-shadow: -4px 0 4px rgba(0, 0, 0, 0.5), 4px 0 4px rgba(0, 0, 0, 0.5) !default;
$off-canvas__container-overlay__z-index: 998 !default;
$off-canvas__menu__width: 75% !default;
$off-canvas__menu__content__background-color: #452702 !default;
$off-canvas__menu__content__background: $off-canvas__menu__content__background-color !default;
$off-canvas__menu__content__list-item__padding: rem-calc(10) $gap/2 !default;
$off-canvas__menu__content__list-item__border-bottom-color: #000 !default;
$off-canvas__menu__content__list-item__border-bottom: 1px solid $off-canvas__menu__content__list-item__border-bottom-color !default;
$off-canvas__menu__content__list-item__label__background-color: #573505 !default;
$off-canvas__menu__content__list-item__label__background: $off-canvas__menu__content__list-item__label__background-color !default;
$off-canvas__menu__content__list-item__label__border-top-color: #925a09 !default;
$off-canvas__menu__content__list-item__label__border-top: 1px solid $off-canvas__menu__content__list-item__label__border-top-color !default;
$off-canvas__menu__content__list-item__label__color: #999999 !default;
$off-canvas__menu__content__list-item__label__text-transform: uppercase !default;
$off-canvas__menu__content__list-item__label__font-weight: null !default;
$off-canvas__menu__content__list-item__label-link__background-color: $off-canvas__menu__content__list-item__label__background-color !default;
$off-canvas__menu__content__list-item__label-link__background: $off-canvas__menu__content__list-item__label-link__background-color !default;
$off-canvas__menu__content__list-item__label-link__background--hover: shade($off-canvas__menu__content__list-item__label-link__background-color, $base__color-shift) !default;
$off-canvas__menu__content__list-item__label-link__color: $off-canvas__menu__content__list-item__label__color !default;
$off-canvas__menu__content__list-item__label-link__color--hover: $off-canvas__menu__content__list-item__label__color !default;
$off-canvas__menu__content__list-item__link__background-color: $off-canvas__menu__content__background-color !default;
$off-canvas__menu__content__list-item__link__background: null !default;
$off-canvas__menu__content__list-item__link__background--hover: shade($off-canvas__menu__content__list-item__link__background-color, $base__color-shift) !default;
$off-canvas__menu__content__list-item__link__color: #cdc3ae !default;
$off-canvas__menu__content__list-item__link__color--hover: $off-canvas__menu__content__list-item__link__color !default;
$off-canvas__menu__content__dropdown__list__background-color: #351f04 !default;
$off-canvas__menu__content__dropdown__list__background: $off-canvas__menu__content__dropdown__list__background-color !default;
$off-canvas__menu__content__dropdown__list__border-top: 1px solid #000 !default;
$off-canvas__menu__content__dropdown__list-item__border-bottom: 1px solid #000 !default;
$off-canvas__menu__content__dropdown__list-item__link__padding: rem-calc(7) $gap/2 !default;
$off-canvas__menu__content__dropdown__list-item__link__background--hover: $off-canvas__menu__content__list-item__link__background-color !default;
$off-canvas__menu__content__dropdown__list-item__link__color: $anchor-color !default;
$off-canvas__menu__content__dropdown__list-item__link__color--hover: $anchor-color-hover !default;

.off-canvas__wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.off-canvas__container {
  @include single-transition(transform, .3s, ease);
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateX(0);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}
.off-canvas__container--open{
  position: $off-canvas__container__open__position;
  z-index: $off-canvas__container__open__z-index;
  transform: translateX($off-canvas__menu__width);
  transform: translate3d($off-canvas__menu__width, 0, 0);

  &--right{
    position: $off-canvas__container__open__position;
    z-index: $off-canvas__container__open__z-index;
    transform: translateX(- $off-canvas__menu__width);
    transform: translate3d(- $off-canvas__menu__width, 0, 0);
  }
  & .off-canvas__menu__content,
  &--right .off-canvas__menu__content{
    visibility: visible;
    &--hide-via-display{
      display: block;
    }
    &--seo-friendly {
      @include element-invisible-off();
    }
  }
}
.off-canvas__container--repaint{
  //transform: translateZ(0);
}
.off-canvas__container__overlay{
  position: fixed;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($off-canvas__container-overlay__background-color, $off-canvas__container-overlay__background-opacity);
  box-shadow: $off-canvas__container-overlay__box-shadow;
  z-index: $off-canvas__container-overlay__z-index;
}
.off-canvas__menu {
  width: $off-canvas__menu__width;
  height: 100%;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  background: $off-canvas__menu__content__background;
  top: 0;
  left: - $off-canvas__menu__width;
  z-index: $off-canvas__container__open__z-index;
  &--right{
    left: auto;
    right: - $off-canvas__menu__width;
  }
}

.off-canvas__menu__content{
  @include single-transition(visibility);
  visibility: hidden; // this will allow transitions within this element to work when classes are swapped via js.
  &--hide-via-display{
    display: none; // deep hide
  }
  &--seo-friendly {
    @include element-invisible(); // this fixes a bug where tab key shifts screen to show form elements in off canvas content
  }
}
.off-canvas__menu__content__list{
  list-style: none;
  margin: 0;
  &-item {
    border-bottom: $off-canvas__menu__content__list-item__border-bottom;
    &:last-child{
      border-bottom: none;
    }
    &--padded{
      padding: $off-canvas__menu__content__list-item__padding;
    }
  }
  &-item__label,
  &-item__label-link,
  &-item__link {
    @include single-transition(background);
    display: block;
    padding: $off-canvas__menu__content__list-item__padding;
    @at-root .off-canvas__menu__content__list-item__link.toggled__show .off-canvas__menu__content__list-item__link__toggle-icon{
      display: inline-block;
      transform: rotate(180deg);
    }
  }
  &-item__label {
    background: $off-canvas__menu__content__list-item__label__background;
    border-top: $off-canvas__menu__content__list-item__label__border-top;
    color: $off-canvas__menu__content__list-item__label__color;
    text-transform: $off-canvas__menu__content__list-item__label__text-transform;
    font-weight: $off-canvas__menu__content__list-item__label__font-weight;
    &-link{
      background: $off-canvas__menu__content__list-item__label-link__background;
      color: $off-canvas__menu__content__list-item__label-link__color;
      text-transform: $off-canvas__menu__content__list-item__label__text-transform;
      font-weight: $off-canvas__menu__content__list-item__label__font-weight;
      &:hover, &:focus{
        background: $off-canvas__menu__content__list-item__label-link__background--hover;
        color: $off-canvas__menu__content__list-item__label-link__color--hover;
      }
    }
  }
  &-item__link {
    background: $off-canvas__menu__content__list-item__link__background;
    color: $off-canvas__menu__content__list-item__link__color;
    &:hover, &:focus{
      background: $off-canvas__menu__content__list-item__link__background--hover;
      color: $off-canvas__menu__content__list-item__link__color--hover;
    }
  }
}

.off-canvas__menu__content__dropdown{
  list-style: none;
  margin: 0;
  &__heading__link{
  }
  &__heading__icon{
  }
  &__list{
    @include element-invisible();
    list-style: none;
    margin: 0;
    background: $off-canvas__menu__content__dropdown__list__background;
    border-top: $off-canvas__menu__content__dropdown__list__border-top;
    &.show{
      @include element-invisible-off();
    }
    &-item{
      border-bottom: $off-canvas__menu__content__dropdown__list-item__border-bottom;
    }
    &-item-link,
    &-item__link{
      display: block;
      padding: $off-canvas__menu__content__dropdown__list-item__link__padding;
      color: $off-canvas__menu__content__dropdown__list-item__link__color;
      @include single-transition(background);
      &:hover, &:focus{
        color: $off-canvas__menu__content__dropdown__list-item__link__color--hover;
        background: $off-canvas__menu__content__dropdown__list-item__link__background--hover;
      }
    }
    &-item__text-link{
      display: block;
      color: $off-canvas__menu__content__dropdown__list-item__link__color;
      &:hover, &:focus{
        color: $off-canvas__menu__content__dropdown__list-item__link__color--hover;
      }
    }
  }
}

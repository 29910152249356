.button{
  &.tada{
  }
  &.is-expanded{
    width: 100%;
  }
  &.has-icon-on-right{
    padding-right: 0;
  }
  &.has-shadow{
    @include shadows(1);
  }
  &.has-shadow--2{
    @include shadows(2);
  }
  &.has-shadow--3{
    @include shadows(3);
  }
    &.is-white.is-outlined{
        border-color: $blue-dark;
        color: $blue-dark;
        background-color: $white;
        &:hover,
        &.is-hovered,
        &:active,
        &.is-active,{
            background-color: $white;
            border-color: $blue;
            color: $blue;
        }
        &:focus,
        &.is-focused{
            border-color: $blue-dark;
            color: $blue-dark;
            &:not(:active){
                box-shadow: 0 0 0 0.125em rgba($grey, 0.25);
            }
        }
    }
  &.is-grey{
    background-color: $grey-lighter;
    border-color: $grey-light;
    color: $grey-dark;
    &:hover,
    &.is-hovered{
      background-color: shade($grey-lighter, $base__color-shift);
      border-color: $grey-light;
      color: $grey-dark;
    }
    &:focus,
    &.is-focused{
      border-color: $grey-light;
      color: $grey-dark;
      &:not(:active){
        box-shadow: 0 0 0 0.125em rgba($grey, 0.25);
      }
    }
    &:active,
    &.is-active{
      background-color: shade($grey-lighter, $base__color-shift);
      border-color: $grey-light;
      color: $grey-dark;
    }
  }
  &--has-auto-height{
    height: auto;
    white-space: normal;
  }
  &--flat-left{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.card-image > .fa {
  font-size: 8rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #209cee;
}
.card-content .content {
  font-size: 14px;
  margin: 1rem 1rem;
}
.card-content .content {
  // had to do this to override bulma's nesting
  h4 {
    font-size: nth($h4__font-sizes, 1);
  }
  h5 {
    font-size: nth($h5__font-sizes, 1);
  }
  h6 {
    font-size: nth($h6__font-sizes, 1);
  }
}
.card {
  @include shadows(1);
  margin-bottom: 0;
  height: 100%;
  border-radius: $base__border__radius;
}

.card__slide-in-left{
  //transform: translate3d(-100%, 0, 0);
  visibility: hidden;
  &.animated{
    visibility: visible;
  }
}
.card__zoom-in{
  //transform: translate3d(-100%, 0, 0);
  visibility: hidden;
  &.animated{
    visibility: visible;
  }
}
.card__slide-in-right{
  //transform: translate3d(100%, 0, 0);
  visibility: hidden;
  &.animated{
    visibility: visible;
  }
}


@include tablet{
  .card-content .content{
    // had to do this to override bulma's nestin
    h4 {
      font-size: nth($h4__font-sizes, 2);
    }
    h5 {
      font-size: nth($h5__font-sizes, 2);
    }
    h6 {
      font-size: nth($h6__font-sizes, 2);
    }
  }
}

@include desktop{
  .card-content .content{
    // had to do this to override bulma's nesting
    h4 {
      font-size: nth($h4__font-sizes, 3);
    }
    h5 {
      font-size: nth($h5__font-sizes, 3);
    }
    h6 {
      font-size: nth($h6__font-sizes, 3);
    }
  }
}

@include widescreen{
  .card-content .content {
    // had to do this to override bulma's nesting
    h4 {
      font-size: nth($h4__font-sizes, 4);
    }
    h5 {
      font-size: nth($h5__font-sizes, 4);
    }
    h6 {
      font-size: nth($h6__font-sizes, 4);
    }
  }
}
.countdown{
  &__headline{
    margin-bottom: 0;
    &--normalized{
      color: $grey-darker;
      font-weight: 400;
    }
  }
  &__container{
    display: inline-block;
    vertical-align: middle;
  }
  &__trailing-text{
    display: inline-block;
  }
  &__table{
    display: table;
    border-collapse: collapse;
  }
  &__row{
    display: table-row;
    &__numbers{
    }
    &__description{
      background: $grey-light;
    }
  }
  &__cell{
    display: table-cell;
    padding: 2px 8px;
    text-align: center;
    &__numbers,
    &__description{
      border-right: 1px solid $grey-lighter;
      &:last-child{
        border-right: none;
      }
    }
    &__numbers{
      font-size: rem-calc(18);
      color: $blue;
      &--seconds{
        color: $orange;
      }
    }
    &__description{
      font-size: 12px;
      color: #ffffff;
      border-right-color: $grey;
    }
  }
}
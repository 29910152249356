.article {
  margin-bottom: rem-calc($gap);
  &__header {
    margin-bottom: rem-calc($gap);
  }
  &__header__img__column,
  &__header__titles,
  &__header__titles.article__header__titles--banner{
    text-align: center;
  }
  &__header__titles,
  &__header__img__column,
  &__main-content{
    padding-right: rem-calc($gap/2);
    padding-left: rem-calc($gap/2);
  }
  &__table{
    &__row{
      border-bottom: 1px solid $grey-lighter;
    }
    &__cell{
      padding: rem-calc(6px) rem-calc(8px);
    }
  }
  &__disclaimer {
    font-style: italic;
    font-size: rem-calc($base__font-size * .8);
    color: $grey-dark;
    margin-top: rem-calc($gap / 2);
    margin-bottom: rem-calc($gap / 2);
  }
}
@include tablet{
  .article {
    &__header__titles{
      text-align: left;
    }
  }
  .article__table{
    display: table;
    border-collapse: collapse;
    &__row{
      display: table-row;
    }
    &__cell{
      display: table-cell;
      //padding: rem-calc(6px) rem-calc(8px);
    }
  }
}
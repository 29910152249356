.tag{
  &.is-outlined{
    &:not(body){
      background-color: transparent;
    }
    &.is-danger{
      border: 1px solid $danger;
      color: shade( $danger, $base__color-shift );
    }
  }
  &.push-1{
    margin-right: rem-calc( $gap / 2 );
  }
}
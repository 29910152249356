.tax-forms{
  &__list{

  }
  &__list__item{
    @include single-transition(all);
    border-bottom: 1px solid $grey-lighter;
    border-radius: 0;
    margin-bottom: 0;
    background-color:rgba($white, 0);
    &--show-details{
      border: 1px solid $grey-lighter;
      border-radius: $base__border__radius;
      background-color:rgba($white, 1);
    }
  }
  &__list__item__title{
    @include single-transition(all, 500ms);
    margin-bottom: 0;
    padding: rem-calc( $gap / 2 );
    background-color:rgba($grey-lighter, 0);
    &:hover,
    &:focus{
      cursor: pointer;
      background-color:rgba($grey-lighter, 1);
    }
    @at-root .tax-forms__list__item--show-details &{
      background-color:rgba($grey-lighter, 1);
    }
  }
  &__list__item__title__icon{
    @include single-transition( transform, 150ms );
    display: inline-block;
    transform: rotate(0);
    @at-root .tax-forms__list__item--show-details &{
      transform: rotate(180deg);
    }
  }
  &__list__item__content{
    @include element-invisible;
    @include single-transition(all);
    padding: 0;
    @at-root .tax-forms__list__item--show-details &{
      @include element-invisible-off;
      padding: rem-calc( $gap );
    }
  }
}
.list{
  list-style-type: disc;
  margin-left: rem-calc($gap / 2);
  margin-bottom: rem-calc( $gap / 2 );
  & li {
    margin-bottom: rem-calc( $gap / 6 );
  }
  & > li > ul{
    list-style-type: circle;
    margin-left: rem-calc($gap / 2);
  }
  @at-root ol.list{
    list-style-type: decimal;
    &--lower-alpha{
      list-style-type: lower-alpha;
    }
  }
}

.benefits{
  &__title{
    border-bottom: 1px solid $grey-lighter;
    padding-bottom: rem-calc( $gap );
    text-align: center;
  }
  &__list{
    display: table;
    margin: auto;
    list-style: none;
  }
  &__list-item{
    margin-bottom: rem-calc( 20 );
    padding-bottom: 20px;
    border-bottom: 1px solid $smoke;
  }
  &__floating__animation{
    margin: auto;
    &--cabbage-patch{
      width: rem-calc(125);
    }
  }
  &__inline__logo__icons{
    display: block;
    margin: auto;
  }
  &__inline__logo__icon__img{
    max-width: rem-calc( 64 );
    display: inline-block;
    padding: 0 rem-calc( $gap / 4 );
  }
}

@include tablet{
  .benefits{
    &__title{
      margin-bottom: rem-calc( $gap );
    }
    &__floating__animation{
      float: right;
      &--cabbage-patch{
        width: rem-calc(200);
      }
    }
    &__inline__logo__icons{
      display: inline-block;
      white-space: nowrap;
    }
    &__inline__logo__icon__img{
      display: inline-block;
      padding: 0 rem-calc( $gap / 3 );
    }
  }
}